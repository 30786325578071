import React, { useState, useEffect } from "react";
import Card, { ReactionIcons } from "components/global/Card";
import Link from "next/link";

import { fetchData, fetchMetaData, processBannerImage, slugify, checkReadStatus, toTimestamp } from "helpers/functions";

// Algolia Search
import algoliasearch from "algoliasearch/lite";
import {
    InstantSearch,
    Configure,
    InfiniteHits,
    RangeInput,
} from "react-instantsearch-dom";

import styles from "styles/pages/Home.module.scss";

type Props = {
    articles?: any;
};

const algolia_app_id: string = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string;
const algolia_api_key: string = process.env
    .NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY as string;
let algolia_articles = process.env.NEXT_PUBLIC_ALGOLIA_CONTENT;
// algolia_articles = 'staging_content_search';
let algolia_ce: any = process.env.NEXT_PUBLIC_ALGOLIA_CE;
// algolia_ce = 'staging_brief_and_campaign_search';
let algolia_fgd: any = process.env.NEXT_PUBLIC_FGD_INDEX;
const searchClient: any = algoliasearch(algolia_app_id, algolia_api_key);

const FeelGoodSection = () => {
    const [customCards, setCustomCards] = useState<any>(null);

    const getCustomCards = async () => {
        const customCardsAPI: any = await fetchData(`feel-good-design/custom-cards`);
        setCustomCards(customCardsAPI);
    };

    useEffect(() => {
        if (customCards == null) {
            getCustomCards();
        }
    }, []);

    return (
        <>
            {customCards && !customCards.error && customCards.res.data.length > 0 ? (
                <div className={'ais-InfiniteHits'}>
                    <div className={'ais-InfiniteHits-list'}>
                        {customCards.res.data.slice(0, 6).map((item: any, index: any) => {
                            console.log('fgd item:', item);
                            let card: any = { ...item };
                            return <Hit key={`custom-card-${index}`} hit={card} />
                        })}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

const Latest = ({ articles }: Props) => {
    const [activeTab, setActiveTab] = useState<any>('all');
    const [activeIndex, setActiveIndex] = useState<any>(algolia_articles);
    const [allArticles, setAllArticles] = useState<any>(null);
    const [allIndex, setAllIndex] = useState<any>(0);

    const queries = [
        {
            indexName: `${algolia_articles}_date_dec`,
            query: '',
            params: {
                hitsPerPage: 1000,
                filters: 'object_type:article OR object_type:articles'
            }
        }, {
            indexName: `${algolia_ce}_latest`,
            query: '',
            params: {
                hitsPerPage: 1000,
            }
        }, {
            indexName: `${algolia_fgd}_date_desc`,
            query: '',
            params: {
                hitsPerPage: 1000,
                filters: 'object_type:custom_card OR object_type:customCard'
            }
        }
    ];

    searchClient.multipleQueries(queries).then(({ results }: any) => {
        let allArr: any = [];
        if (results && results.length > 0) {
            // console.log('all results', results);
            results.forEach((result: any) => {
                if (result.hits && result.hits.length > 0) {
                    allArr = allArr.concat(result.hits);
                }
            })
        }

        if (!allArticles && allArr.length > 0) {
            allArr.sort((a: any, b: any) => b.published_at - a.published_at)
            setAllArticles(allArr);
        }
    });

    const [searchDates, setSearchDates] = useState({
        start: toTimestamp(new Date(new Date().setFullYear(new Date().getFullYear() - 1))) / 1000,
        end: toTimestamp() / 1000,
    });

    return (
        <section id={'latest'} className={`newest articles-container ${styles.newest}`}>
            <div className={'wrapper'}>
                <div className="title">
                    <h2 className="h1">The Latest</h2>
                </div>
                <ul className={styles.tabs}>
                    <li key={'all-tab'}>
                        <button className={`${activeTab === 'all' ? styles.active : ''} latest-filter-tab latest-all-tab`} onClick={(e: any) => {
                            e.preventDefault();
                            if (activeTab !== 'all') {
                                setActiveTab('all');
                                setActiveIndex(algolia_articles);
                                setAllIndex(0);
                            }
                        }}>All</button>
                    </li>
                    <li key={'articles-tab'}>
                        <button className={`${activeTab === 'articles' ? styles.active : ''} latest-filter-tab latest-articles-tab`} onClick={(e: any) => {
                            e.preventDefault();
                            if (activeTab !== 'articles') {
                                setActiveTab('articles');
                                setActiveIndex(algolia_articles);
                                setAllIndex(0);
                            }
                        }}>Articles</button>
                    </li>
                    <li key={'ce-tab'}>
                        <button className={`${activeTab === 'ce' ? styles.active : ''}  latest-filter-tab latest-briefs-campaigns-tab`} onClick={(e: any) => {
                            e.preventDefault();
                            if (activeTab !== 'ce') {
                                setActiveTab('ce');
                                setActiveIndex(algolia_ce);
                                setAllIndex(0);
                            }
                        }}>Briefs and Campaigns</button>
                    </li>
                    <li key={'fgd-tab'}>
                        <button className={`${activeTab === 'fgd' ? styles.active : ''}  latest-filter-tab latest-fgd-tab`} onClick={(e: any) => {
                            e.preventDefault();
                            if (activeTab !== 'fgd') {
                                setActiveTab('fgd');
                                setActiveIndex(algolia_fgd);
                                setAllIndex(0);
                            }
                        }}>Feel-Good Design</button>
                    </li>
                </ul>

                {activeTab === 'all' && (
                    <>
                        {allArticles && allArticles.length > 0 ? (
                            <div className={'ais-InfiniteHits'}>
                                <div className={'ais-InfiniteHits-list'}>
                                    {allArticles.slice(0, 6 + (6 * allIndex)).map((article: any, index: any) => {
                                        return <Hit hit={article} key={`latest-item-${index}`} />
                                    })}
                                </div>
                                <button className={`btn ais-InfiniteHits-loadMore ${allArticles.length <= (6 + (6 * allIndex)) ? 'hidden' : ''}`} onClick={(e) => {
                                    e.preventDefault();
                                    setAllIndex(allIndex + 1);
                                }}>View More</button>
                            </div>
                        ) : (
                            <p>No results found</p>
                        )}
                    </>
                )}
                {(activeTab === 'articles' || activeTab === 'ce' || activeTab === 'fgd') && (
                    <InstantSearch
                        indexName={`${activeIndex}${activeTab === 'ce' ? '_latest' : activeTab === 'fgd' ? '_date_desc' : '_date_dec'}`}
                        searchClient={searchClient}>
                        {activeTab === 'articles' ? (
                            <Configure
                                hitsPerPage={6}
                                filters='object_type:article OR object_type:articles'
                            />
                        ) :  activeTab === 'fgd' ? (
                            <Configure
                                hitsPerPage={6}
                                filters='object_type:custom_card OR object_type:customCard'
                            />
                        ) : (
                            <Configure
                                hitsPerPage={6}
                            />
                        )}
                        <RangeInput
                            attribute="published_at"
                        />
                        <InfiniteHits hitComponent={Hit} />
                    </InstantSearch>
                )}
            </div>
        </section>
    )
}

const Hit = (props: any) => {
    const post: any = { ...props.hit };
    // console.log(post);
    // console.log('post', post);

    let image: any = post.banner ? processBannerImage(post.banner, true) : '/images/null-placeholder.png';
    if (post.custom_image_url) {
        image = processBannerImage(post.custom_image_url, true);
    }
    if (post.banner_image) {
        image = processBannerImage(post.banner_image, true);
    }
    if(post.card_thumbnail) {
        image = processBannerImage(post.card_thumbnail, true);
    }

    // console.log(post.title, image);
    // console.log('post', post);
    let date: any = post.published_at;
    let award: any = null;
    if (post.object_type && (post.object_type === 'campaign' || post.object_type === 'brief')) {
        date = post.campaign_year;
    }

    if (post.award) {
        award = post.award;
    }

    if (post.campaign_award) {
        award = post.campaign_award;
    }

    let description: any = post.short_description ? post.short_description : ``;

    if (post.post_introduction) {
        description = post.post_introduction;
    }

    if (post.takeaway_text) {
        description = post.takeaway_text;
    }

    if(post.card_text) {
        description = post.card_text;
    }

    const [hasRead, setHasRead] = useState<boolean>(false);
    const [hasBookmarked, setHasBookmarked] = useState<boolean>(false);

    async function fetchPage() {
        const pageData = await checkReadStatus(post.id);
        if (pageData.loaded && !pageData.error && pageData.res.data && pageData.res.data.length > 0) {
            if (pageData.res.data[0].user_viewed) {
                setHasRead(true);
            }
            if (pageData.res.data[0].user_bookmarked) {
                setHasBookmarked(true);
            }
        }
    }

    // useEffect(() => {
    //     if (post.object_type === 'article') {
    //         fetchPage();
    //     }
    // }, []);

    const [metaData, setMetaData] = useState<any>(null);

    async function getMetaData() {
        let params: any = {}
        const key: string = post.object_type + 's';
        params[key] = [post.id];
        const pageMetaData: any = await fetchMetaData(params);
        if (!pageMetaData.error && pageMetaData.res && pageMetaData.res.data && pageMetaData.res.data.length > 0) {
            const res: any = pageMetaData.res.data[0];
            setMetaData(res);
        }
    }

    useEffect(() => {
        if (!metaData) {
            getMetaData();
        }
    }, []);

    return (
        <Card
            key={`${post.object_type ? post.object_type : post.type}-${post.slug ? post.slug : slugify(post.title)}`}
            hit={post}
            article_id={post.id ? post.id : post.article_id}
            title={post.title}
            country={post.market ? post.market : post.country}
            badges={null}
            date={post.published_at ? post.published_at * 1000 : post.date_published} // need date
            slug={post.slug}
            fileUrl={post.url ? post.url : null}
            viewCount={metaData ? metaData.views : post.views}
            hasRead={metaData ? metaData.user_viewed : null}
            hasBookmarked={metaData ? metaData.user_bookmarked : false}
            // NEED IMAGE
            image={{
                alt: "alt txt",
                src: image,
            }}
            // displayLarge={
            //     i === 0 ? true : false
            // }
            short_description={description}
            reactions={post.reactions}
            additionalClasses={`gridCard`}
            topics={post.topic ? post.topic : post.category}
            object_type={post.object_type ? post.object_type : post.type}
            award={award}
            custom_type={post.card_type ? post.card_type : null}
        />
    )
}

export default Latest;